import styled from "@emotion/styled";
import { Num7, NumBlue, NumGreen, NumOrange } from "assets/images";
import { numberInfo, sizes, statusColors } from "service";

export const NumberContainer = styled.div`
  position: relative;
  width: ${sizes.numberWidth}px;
  height: ${sizes.numberWidth}px;
  border-radius: 9px;
  background: url(${(props) => numberInfo[props.status].background});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.number7 {
    background: url(${props => props.status === statusColors.green ? NumGreen : props.status === statusColors.blue ? NumBlue : props.status === statusColors.orange ? NumOrange : Num7});
    background-size: cover;
    color: white;
  }
  span {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .icon-check-num,
  .icon-free-num {
    position: absolute;
    opacity: 0.9;
  }
  .add-score-value {
    position: absolute;
  }
`;
